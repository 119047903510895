





































import { ref } from '@nuxtjs/composition-api';
import { SfInput, SfIcon } from "@storefront-ui/vue";
import debounce from 'lodash.debounce';
import { useProductsWithCommonProductCardProps } from '~/modules/catalog/category/components/views/useProductsWithCommonCardProps';
import { useProduct } from '~/modules/catalog/product/composables/useProduct';
import { useIsMobile } from '@/helpers/mobileDevices';
import { ProductRepository } from '../../../repository/products.repository';
const __sfc_main = {};
__sfc_main.props = {
  isSearchOpen: {
    type: Boolean,
    default: false
  },
  itemsPerPage: {
    type: Number,
    default: 12
  },
  minTermLen: {
    type: Number,
    default: 0
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const props = __props;
  const {
    isMobile
  } = useIsMobile();
  const term = ref('');
  const {
    getProductList
  } = useProduct();
  const productRepository = new ProductRepository();
  const productsWithCommonProductCardPropsData = ref([]);
  const {
    productsWithCommonProductCardProps
  } = useProductsWithCommonProductCardProps(productsWithCommonProductCardPropsData);
  const debouncedSearch = debounce(async () => {
    let productList;
    emit('set-loading-product', true);
    emit('set-debounced-search-term', term.value);
    if (term.value.length < props.minTermLen) {
      emit('set-loading-product', false);
      return;
    }
    if (term.value !== '') {
      productList = await getProductList({
        pageSize: props.itemsPerPage,
        search: term.value
      });
    } else {
      const bestProductsSearch = await getBestProductSkuSearch();
      productList = await getProductList({
        pageSize: props.itemsPerPage,
        filter: {
          sku: {
            in: bestProductsSearch
          }
        }
      });
    }
    productsWithCommonProductCardPropsData.value = productList?.items;
    emit('set-loading-product', false);
    emit('set-search-results', productsWithCommonProductCardProps.value);
  }, 1000);
  const handleSearch = () => {
    emit('set-search-term', term.value);
    debouncedSearch();
  };
  const getBestProductSkuSearch = async () => {
    try {
      const bestProductSku = await productRepository.getBestProduct();
      return bestProductSku;
    } catch (e) {
      console.error('Erreur lors de la récupération des produits:', e);
    }
  };
  const clearSearch = () => {
    term.value = '';
    debouncedSearch();
  };
  const closeSearch = (event: MouseEvent) => {
    const searchResultsEl = document.querySelector('.search');
    const closeTriggerElement = (event.target as HTMLElement);
    if (!searchResultsEl?.contains(closeTriggerElement)) {
      emit('set-search-results', null);
      term.value = '';
    }
  };
  return {
    isMobile,
    term,
    handleSearch,
    clearSearch
  };
};
__sfc_main.components = Object.assign({
  SfIcon,
  SfInput
}, __sfc_main.components);
export default __sfc_main;
